import React, { useState } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Fade, Button } from '@material-ui/core';

// -----------Icons -------------
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

// ----------- Functions -------------
import humanReadableBytes from 'functions/human-readable-bytes.js';
import sleep from 'functions/sleep.js';

// ------- Styles -------
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
   
    errorTooLarge:{
        position: "fixed",
        height: '50px',
        width: '200vh !important',
        top: '70px !important', 
        left: "0px",
        zIndex: 1,
    },

        
    }));


const FileUploader = props => {
    const classes = useStyles();
    const [fileSizeErrorOpen, setFileSizeErrorOpen] = useState(false);

    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null);

    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file 
    const handleChange = event => {
        const fileUploaded = event.target.files[0];

        if (fileUploaded.size > props.maxFileSize) {
            setFileSizeErrorOpen(true)
        } else {
            props.handleFile(fileUploaded);
        }
    };

    const verifyFileSizeError = () => {
        if (fileSizeErrorOpen) {
            sleep(4000)
                .then(() => {
                    setFileSizeErrorOpen(false)
                })

            return (
                <div className={classes.errorTooLarge} >
                    <Fade in={fileSizeErrorOpen} >
                            <Alert variant="filled" severity="error" >
                                <AlertTitle>File Size Error</AlertTitle>
                                Max file size: <strong> {humanReadableBytes(props.maxFileSize)}</strong>
                            </Alert>
                    </Fade>
                    
                </div>
            )
        }
    };

    return (
        <div>
            {verifyFileSizeError()}
            <label htmlFor="file">
                <Button startIcon={<CloudUploadIcon />} variant="contained" size="large" color="primary" component="span" id="fileUploadButton">Upload</Button>
            </label>
            <input
                type="file"
                id="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: 'none' }}
            />
        </div>
    );
}
export default FileUploader;