import { React } from "react";
import { Container, Typography } from '@material-ui/core';

// ------- Icons -------
import header from 'assets/1000px-View_of_Atlanta_at_night_from__SCADpad_.png';

// ------- Components -------
import ContactForm from 'components/contact-form';

// ------- Styles -------
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  header: {
    minWidth: '100%',
    maxWidth: '100%',
    [theme.breakpoints.down('xs')]: {
      height: '60vh',
    },
    [theme.breakpoints.up('sm')]: {
      height: '40vh',
    },
    width: '100vh',
    backgroundColor: "#191919",
    padding: '0px',
    position: 'relative'
  },

  headerImage: {
    height: '100%',
    width: '100%',
    backgroundImage: 'url(' + header + ')',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    overflow: 'hidden',
    filter: 'blur(2.5px)',
    webkitFilter: 'blur(2.5px)',
    position: 'absolute',
    zIndex: '0'
  },

  headerContainer: {
    padding: "28px",
    paddingTop: "89px",
    zIndex: '1',
    color: 'white',
    textShadow: '2px 2px 2px black',
    position: 'relative',
    align: "center",
    justify: "center",
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  headerText: {
  },

  body: {
    padding: theme.spacing(10, 0, 6),
    flexGrow: 1,
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '10%',
      paddingRight: '10%',
    },
    [theme.breakpoints.up('lg')]: {
    },
  },

  card: {
    maxWidth: 300,
    minWidth: 250,
    paddingTop: 20,
    paddingBottom: 20,
    marginLeft: 'auto',
    marginRight: 'auto'
  },

  typography: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

  contactForm: {
    height: "100vh",
  },

  svgIcons: {
    fontSize: "6em",
    color: theme.palette.secondary.main,
  },

  contactus: {

  },

  contactCard: {
    maxWidth: 850,
    padding: "20px 5px",
    margin: "0 auto"
  },

}));

// ----- End Styles -----

function ContactUs() {
  const classes = useStyles();

  return (
    <div>
      <Container className={classes.header} >
        <img loading="eager" className={classes.headerImage} src={header} alt="ATL Skyline at night" />
        <Container maxWidth="sm" className={classes.headerContainer}>
          <Typography variant='h2' align="center" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant='h6' align="center" className={classes.headerText} gutterBottom>
            Have a question, comment, concern, feature request, or just want to talk with us? We would love to hear from you!
          </Typography>
        </Container>
      </Container>
      <ContactForm />
    </div>
  );
}

export default ContactUs;