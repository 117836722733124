export default function obfuscateFileName(file) {
  let characters =
    ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r",
      "s", "t", "u", "v", "w", "x", "y", "z", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  let randString = ""
  let fileType = file.name.split('.')

  for (let i = 0; i < 16; i++) {
    randString += characters[Math.floor(Math.random() * 36)]
  }

  // Files with empty strings have no substrings
  if (fileType.length > 1)
    randString = randString + "." + fileType[fileType.length - 1]

  let obfuscatedFile = new File([file], randString, { type: file.type })

  return obfuscatedFile
}