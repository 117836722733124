import axios from 'axios';
import sleep from 'functions/sleep.js';

// Need to get rid of Content-Disposition header on s3 or we will have to store the file name

// https://stackoverflow.com/questions/7428831/javascript-rename-file-on-download
const downloadFile = (url, deleteUrl, setFileDownloaded, fileName) => {
  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  a.click()
  
  axios.get(
    url, 
    {
      headers: {
        "Content-Type": "application/octet-stream"
      },
      responseType: "blob"
    }
  )
  .then(response => {
    const a = document.createElement("a");
    const url = window.URL.createObjectURL(response.data);
    console.log(response.data)
    a.href = url;
    a.download = fileName;
    a.click();

    if(!(deleteUrl === null)){
      sleep(2000)
        .then(() => {
          axios.delete(
            deleteUrl, 
            {
              headers: {
                "Content-Type": "application/octet-stream"
              },
              responseType: "blob"
            }
          )
            .then(response => {
              setFileDownloaded(true)
            })
        })
    }
  })
  .catch(err => {
    console.log("error", err);
  });
};

export default downloadFile;