async function getSecretString(url, guid) {
    
    const request_url = url + '?guid=' + guid

    const result = await fetch(request_url)
        .then(response => response.json())
        .then(data => {
            if (data['message']) {
                const message = `An error has occurred: ${data.status}`;
                throw new Error(message); //ToDo add error handling such as a retry or let the user know something went wrong
            }
            return data
        })
        .catch((error) => {
            return {"error": error}
        }); 

    return result
        
}

export default getSecretString;