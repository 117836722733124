import React from 'react';
import {
    // Grid,
    Box,
    Container,
    // Link,
} from "@material-ui/core";
// ------- Styles -------
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    footerBox:{
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        padding: '15px',
        bottom: '0px',
        width: '100%'
      },
}));

    const Footer = () => {
        const classes = useStyles();
        return(
           <Box className={classes.footerBox}>
               <Container maxWidth="lg">
                   {/* <Grid container spacing={5}>
                       <Grid item xs={12} sm={4}>
                           <Box borderBottom={1}>Help</Box>
                           <Box>
                               <Link href="/" color="inherit">
                                   Contact
                               </Link>
                           </Box>
                           <Box>
                               <Link href="/" color="inherit">
                                   Support
                               </Link>
                           </Box>
                           <Box>
                               <Link href="/" color="inherit">
                                   Privacy
                               </Link>
                           </Box>
                           <Box>
                               <Link href="/" color="inherit">
                                   Legal
                               </Link>
                           </Box>
                       </Grid>
                       <Grid item xs={12} sm={4}>
                           <Box borderBottom={1}>Account</Box>
                           <Box>
                               <Link href="/" color="inherit">
                                   Login
                               </Link>
                           </Box>
                           <Box>
                               <Link href="/" color="inherit">
                                   Register
                               </Link>
                           </Box>
                       </Grid>
                   </Grid> */}
                    <Box textAlign="center" >
                        Signet Technology &copy; {new Date().getFullYear()}
                    </Box>
               </Container>
           </Box>
        )
    }
export default Footer;