import { React, useState } from 'react';
import { Typography, Grid, Container, Button, TextField, Fade } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

// -----------Functions------------
import putContactForm from 'functions/put-contactform';
import appConfig from 'configs/config.js';
import sleep from 'functions/sleep.js';

// ------- Icons -------
import ContactMail from '@material-ui/icons/ContactMail';

// ------- Styles -------
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    svgIcons: {
        fontSize: "6em",
        color: theme.palette.secondary.main,
        align: 'center',
        justify: 'center',
    },

    contactUsBody: {
        padding: theme.spacing(10, 0, 6),
        flexGrow: 1,
        textAlign: 'center',
        [theme.breakpoints.down('lg')]: {
            paddingLeft: '10%',
            paddingRight: '10%',
        },
        [theme.breakpoints.up('lg')]: {
        },
    },

    contactForm: {
        padding: "28px",
        flexGrow: 1,
        height: "200vh",
        textAlign: 'center',

    },

    alertBox: {
        height: '50px',
        width: '100%',
        top: '70px !important',
        left: "0px",
        zIndex: 1,
        marginBottom: "10px",
        position: 'fixed',
    },

}));
// ----- End Styles -----

function Requestdemo() {
  const classes = useStyles();
  const [FirstName, setFirstName] = useState();
  const [LastName, setLastName] = useState();
  const [Email, setEmail] = useState();
  const [Phone, setPhone] = useState();
  const [ContactMessage, setContactMessage] = useState();
  const [contactFormSubmitMessageOpen, setContactFormSubmitMessageOpen] = useState([false, ""]);

  const submitContactForm = () => {

      let invalidFields = []
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/

      // Ensure First name, last name, and message are not empty
      if (typeof FirstName === 'undefined' || FirstName === ''){invalidFields.push("First Name ")}
      if (typeof LastName === 'undefined' || LastName === ''){invalidFields.push("Last Name ")}
      if (typeof ContactMessage === 'undefined' || ContactMessage === ''){invalidFields.push("Message ")}

      //Ensure email is valid
      if (typeof Email === 'undefined' || Email === ''){
          invalidFields.push("Email ")
      } else if (!(Email.match(emailRegex))){
          invalidFields.push("Email ")
      }

      //Ensure phone number, if provided, is valid
      if (!(typeof Phone === 'undefined' || Phone === '')){
          if (!(Phone.match(phoneRegex))){
              invalidFields.push("Phone ")
          }
      }


      if (invalidFields.length === 0) {
          putContactForm(appConfig['contactFormUrl'], FirstName, LastName, Email, Phone, ContactMessage)
              .then(
                  data => {
                      if (data['message'] === "Successfully submitted contact form") {
                          setContactFormSubmitMessageOpen([true, "success"])
                          setContactMessage('')
                          setFirstName('')
                          setLastName('')
                          setEmail('')
                          setPhone('')
                      }
                  })
      } else {
          setContactFormSubmitMessageOpen([true, invalidFields])
      }
  };

  function submitFormAlert() {
      if (contactFormSubmitMessageOpen[0]) {
          sleep(4000)
              .then(() => {
                  setContactFormSubmitMessageOpen([false, ""])
              })

          if (contactFormSubmitMessageOpen[1] === "success") {
              return (
                  <Fade in={contactFormSubmitMessageOpen} >
                      <Container disableGutters={true} className={classes.alertBox} maxWidth={false}>
                          <Alert variant="filled" severity="success">
                              <AlertTitle ><strong>Demo Request submitted</strong></AlertTitle>
                              <Typography > We have received your Demo Inquiry and will respond shortly </Typography>
                          </Alert>
                      </Container>
                  </Fade>
              )
          } else {
              return (
                  <Fade in={contactFormSubmitMessageOpen} >
                      <Container disableGutters={true} className={classes.alertBox} maxWidth={false}>
                          <Alert variant="filled" severity="error">
                              <AlertTitle ><strong>Please fix the following fields:</strong></AlertTitle>
                              <Typography > {contactFormSubmitMessageOpen[1]} </Typography>
                          </Alert>
                      </Container>
                  </Fade>
              )
          }
          
      }
  }

  return (
      <div>
          {submitFormAlert()}
          <section className={classes.contactForm}>
              <Container maxWidth="md" className={classes.contactUsBody} >
                  <section>
                      <Grid>
                          <ContactMail className={classes.svgIcons}></ContactMail >
                          <Typography variant='h3' align="center" color="textPrimary" gutterBottom>
                          Request a Signet Seal Demo
                          </Typography>
                          <Typography variant='h5' align="center" color="textPrimary" gutterBottom>
                          Please fill out the form below and we will get back to you as soon as possible.
                          </Typography>
                          <Typography gutterBottom>
                           Let us know if there's anything specific you'd like to see in the demo. 
                          </Typography>
                          <form>
                              <Grid container spacing={1}>
                                  <Grid xs={12} sm={6} item>
                                      <TextField
                                          id="firstName"
                                          placeholder="Enter first name"
                                          label="First Name"
                                          variant="outlined"
                                          fullWidth required
                                          value={FirstName}
                                          onChange={FirstName => setFirstName(FirstName.target.value)}

                                      />
                                  </Grid>
                                  <Grid xs={12} sm={6} item>
                                      <TextField
                                          id="lastName"
                                          placeholder="Enter last name"
                                          label="Last Name"
                                          variant="outlined"
                                          fullWidth required
                                          value={LastName}
                                          onChange={LastName => setLastName(LastName.target.value)}

                                      />
                                  </Grid>
                                  <Grid item xs={12}>
                                      <TextField
                                          id="email"
                                          type="email"
                                          placeholder="Enter email"
                                          label="Email"
                                          variant="outlined"
                                          fullWidth required
                                          value={Email}
                                          onChange={Email => setEmail(Email.target.value)}

                                      />
                                  </Grid>
                                  <Grid item xs={12}>
                                      <TextField
                                          id="phone"
                                          type="number"
                                          placeholder="Enter phone number"
                                          label="Phone"
                                          variant="outlined"
                                          fullWidth
                                          value={Phone}
                                          onChange={Phone => setPhone(Phone.target.value)}

                                      />
                                  </Grid>
                                  <Grid item xs={12}>
                                      <TextField
                                          id="contactMessage"
                                          label="Message"
                                          multiline rows={4}
                                          placeholder="Type your message here"
                                          variant="outlined"
                                          fullWidth required
                                          value={ContactMessage}
                                          onChange={ContactMessage => setContactMessage(ContactMessage.target.value)}

                                      />
                                  </Grid>
                              </Grid>
                          </form>
                          <Grid item xs={12}>
                              <Button
                                  id="contactFormSubmit"
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={submitContactForm}>Submit</Button>
                          </Grid>
                      </Grid>
                  </section>
              </Container>
          </section>
      </div>
  )
}
  export default Requestdemo;