import { createTheme} from '@material-ui/core';

const useThemes = createTheme({
  palette: {
    primary: {
      main: "#2E77BB"
    },
    secondary: {
      main: "#6CC4EE"
    }
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "Helvetica Neue",
      "Arial",
      "sans-serif"
    ].join(','),
  }
});

export default useThemes;