//This component returns a paper which containers an icon on the left and and header with description on the right

import React, { useState } from 'react';
import { Typography, Grid, Paper, FormControlLabel } from '@material-ui/core';

// ----------- Functions -------------
import humanReadableBytes from 'functions/human-readable-bytes.js';
import sanitizeS3FileName from 'functions/sanitize-s3-file-name.js';

// -----------Icons -------------
import DescriptionIcon from '@material-ui/icons/Description';
import Checkbox from '@material-ui/core/Checkbox';

// ------- Styles -------
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "5px",
        margin: "5px",
    },

    text: {
        textAlign: "left"
    },

}));

function FileDetails(props) {
    const classes = useStyles();
    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
        if(!checked)
            props.obfuscationFunc(true, props.file)
        else
            props.obfuscationFunc(false, props.file)
    };
    
    let humanReadableFileSize = humanReadableBytes(props.file.size)

    const obfuscateFileNameCheckbox = () => {
        if (props.usageType === "upload") {
            return (
                <FormControlLabel control={
                    <Checkbox
                        inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                        checked={checked}
                        onChange={handleChange}
                        color="primary"
                    />}
                    label="Obfuscate File Name"
                />
            )
        }
    };

    return (
        <Paper className={classes.root} id="fileDetailsPaper">
            <Grid container spacing={2} justifyContent="center">
                <Grid item >
                    <DescriptionIcon color="primary"></DescriptionIcon>
                    <Typography>
                        <strong>File</strong>
                    </Typography>
                </Grid>
                <Grid item className={classes.text}>
                    <Typography>
                        Name: {sanitizeS3FileName(props.file.name)}
                    </Typography>
                    <Typography>
                        Size: {humanReadableFileSize}
                    </Typography>
                    {obfuscateFileNameCheckbox()}
                </Grid>
            </Grid>
        </Paper>
    )
}

export default FileDetails;