import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, Fade } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from "react-router-dom";
import { Alert, AlertTitle } from '@material-ui/lab';

// ------- Components -------
import ApiContent from 'components/api-content.jsx';

// ------- Functions -------
import copyStringToClipboard from 'functions/copy-string-to-clipboard.js';
import sleep from 'functions/sleep.js';

// ------- Styles -------
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontWeight: 'bold',
    paddingRight: '3em'
  },

  primaryButtonDiv: {

  },

  buttonLeft: {
    position: 'absolute',
    left: theme.spacing(2),
    display: 'inline-block',
    minWidth: "25%",

  },

  buttonRight: {
    float: 'right',
    right: theme.spacing(2),
    display: 'inline-block',
    textDecoration: "none",
    textAlign: 'center',
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  messageEmptyError: {
    position: 'fixed',
    top: '80px',
    zIndex: '10',
    left: '5%',
    right: '5%'
  }

}));

function PutMessageModal(props) {
  const [open, setOpen] = useState(false);
  const [returnedDataFromAPI, setReturnedDataFromAPI] = useState(null);
  const [showButtons, setShowButtons] = useState(false);
  const [toolTipOpen, setToolTipOpen] = useState(false);
  const [messageEmptyErrorOpen, setMessageEmptyErrorOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  //Continue to update windowWidth with the current window size
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const HandleClose = () => {
    setOpen(false);
    setReturnedDataFromAPI(null)
    handleTooltipClose();
    setShowButtons(false)


    //Only clear message text field if calling modal from retrieve page
    if (typeof props.clearMessageTextField !== 'undefined') {
      props.clearMessageTextField()
    }
  };

  function apiCall(apiCallType) {
    if (apiCallType === 'put') {
      return (
        <ApiContent receiveValue={receiveValue} showButtonsHandler={showButtonsHandler} action={props.action} messageToEncrypt={props.messageToEncrypt} ttl={props.ttl} delete_on_view={props.delete_on_view} fileHolder={props.fileHolder} />
      )
    }
    else if (apiCallType === 'retrieve') {
      return (
        <ApiContent receiveValue={receiveValue} showButtonsHandler={showButtonsHandler} action={props.action} guid={props.guid} delete_on_view={props.delete_on_view} />
      )
    }
  }

  function receiveValue(value) {
    setReturnedDataFromAPI(value);
  }

  function showButtonsHandler(showButtons) {
    setShowButtons(showButtons)
  }

  let apiResponseOutput = apiCall(props.action)
  const onPrimaryButtonClick = () => {
    if (props.messageToEncrypt === '') {
      setMessageEmptyErrorOpen(true);
    } else {
      setOpen(true);
    }
  };

  const handleTooltipClose = () => {
    setToolTipOpen(false);
  };

  const handleTooltipOpen = () => {
    setToolTipOpen(true);
  };

  const onCopyClick = () => {
    copyStringToClipboard(returnedDataFromAPI);
    // console.log("setReturnedDataFromAPI value after copy clicked:" + returnedDataFromAPI)
    handleTooltipOpen()
    sleep(2500)
      .then(() => {
        handleTooltipClose()
      })
  };

  const buttons = () => {
    if (showButtons) { //Show buttons is set after the api call has been made in api-content
      if (windowWidth > 550) {
        return (
          <DialogActions>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={toolTipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="Copied to your clipboard"
            >
              <Button className={classes.buttonLeft} onClick={onCopyClick} variant="contained" color="primary"> Copy </Button>
            </Tooltip>
            <Button className={classes.buttonRight} onClick={HandleClose} variant="contained" color="primary">
              <Link to="/" className={classes.buttonRight} style={{ textDecoration: 'none', color: 'white' }}>Generate another message!</Link>
            </Button>
          </DialogActions>
        )
      } else {
        return (
          <DialogActions>
            <ButtonGroup fullWidth variant="contained" size='large' color="primary" aria-label="contained primary button group">
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={toolTipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Copied to your clipboard"
              >
                <Button onClick={onCopyClick}>Copy</Button>
              </Tooltip>
              <Button onClick={HandleClose}  >
                <Link to="/" className={classes.buttonRight} style={{ textDecoration: 'none', color: 'white' }}>Generate another message!</Link>
              </Button>
            </ButtonGroup>
          </DialogActions>
        )
      }
    }
  }

  const messageEmptyError = () => {
    if (messageEmptyErrorOpen) {
      sleep(4000)
        .then(() => {
          setMessageEmptyErrorOpen(false)
        })
      return (
        <Fade in={messageEmptyErrorOpen} className={classes.messageEmptyError}>
          <Alert severity="error" >
            <AlertTitle>Error</AlertTitle>
            <strong>Message cannot be blank. Please enter a message.</strong>
          </Alert>
        </Fade>
      )
    }
  };

  const classes = useStyles();
  return (
    <div>
      {messageEmptyError()}
      <Button variant="contained" size="large" color="primary" id="stringToEncryptField" onClick={onPrimaryButtonClick}> {props.primaryButtonMessage}</Button>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            HandleClose();
          }
        }}
        fullScreen={false}
      >
        <DialogTitle disableTypography={true} className={classes.title} id="alert-dialog-title">
          <Typography align='left' variant="h5"> {props.title} </Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={HandleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {apiResponseOutput}
        </DialogContent>
        {buttons()}
      </Dialog>
    </div>
  );
}

export default PutMessageModal;