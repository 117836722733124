import React, { useEffect, useCallback, useState } from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Button from '@material-ui/core/Button';
import { Paper, Typography, Container, Grid } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

// ----------- Functions -------------
import putSecretString from 'functions/put-string.js';
import getSecretString from 'functions/get-string.js';
import appConfig from 'configs/config.js';
import putSecretFile from 'functions/put-file.js';
import humanReadableBytes from 'functions/human-readable-bytes.js';
import downloadFile from 'functions/download-file.js';

// ------- Components -------
import FileDetails from 'components/file-details';

// ------- Styles -------
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    loadingIconContainer: {
        height: "7em",
    },

    loadingIcon: {
        position: 'absolute',
        left: '42%',
        top: '50%',
    },

    returnContent: {
        padding: '5px',
    },

    downloadButton: {
        marginTop: "20px",
        alignItems: "center",
    },

    fileUploadMessage: {
        textAlign: "center !important",
        alignItems: "center",
        position: 'flex',
       
    },

    alertBox: {
        marginBottom: "10px",
        textOverflow: 'ellipsis',
        position: 'relative',
        whiteSpace: "nowrap"
    },

    codeBlock: {
        backgroundColor: "#eee",
        padding: "20px",
        textOverflow: 'ellipsis',
        position: 'relative'
    }
  
}));

function PutReturnContent(props) {
    const classes = useStyles();
    const [responseReceived, setResponseReceived] = useState(false);
    const [messageResponse, setMessageResponse] = useState(false);
    const [filePutResponse, setFilePutResponse] = useState(false);
    const [fileResponse, setFileResponse] = useState(false);
    const [fileDownloaded, setFileDownloaded] = useState(false);
    const [fileDeleteURL, setFileDeleteURL] = useState(false);
    const [fileDetails, setFileDetails] = useState({
        "name": "null",
        "size": null
    });
    const [recordNoExist, setRecordNoExist] = useState(false);
    let makeApiCall = ''
    let dialogContentTop = ''
    let dialogContentMessage = ''

    // https://jorpantech.medium.com/react-hook-usecallback-82efba1ab4a9
    makeApiCall = useCallback(() => {
        if (props.action === "put" && props.messageToEncrypt ) {
            putSecretString(appConfig['putFunctionUrl'], props.messageToEncrypt, props.ttl, props.delete_on_view, props.fileHolder)
                .then(
                    data => {
                        if (typeof data['guid'] !== undefined && data['guid']) {
                            //Build out retrieve url. Handle for ability to test locally and for real usage

                            //Handle multi-retrievals, if delete_on_view == false then add "&multi=true" to end of retrieval url
                            const retrieveParameter = (!props.delete_on_view) ? "&multi=true" : ""

                            if (window.location.hostname === "localhost") {
                                setMessageResponse("http://" + window.location.hostname + ':4000/retrieve?guid=' + data['guid'] + retrieveParameter)
                            }
                            else {
                                setMessageResponse("https://" + window.location.hostname + '/retrieve?guid=' + data['guid'] + retrieveParameter);
                            }

                            //Handle file uploads
                            //If there is a file wait on upload to finish to end loading wheel
                            if (props.fileHolder) {
                                putSecretFile(data.presigned_POST_url, props.fileHolder)
                                    .then(
                                        data => {
                                            if (data === "success"){
                                                setFilePutResponse(data)
                                                setResponseReceived(true)
                                            } else {
                                                setFilePutResponse(data)
                                                setResponseReceived(true)
                                            }
                                        }
                                    )
                            } else {
                                setResponseReceived(true)
                            }
                            
                        }
                        else {
                            setResponseReceived("Errorfalse")
                        }
                    }
                )
        } else if (props.action === "retrieve") {
            getSecretString(appConfig['getFunctionUrl'], props.guid)
                .then(
                    data => {
                        if (data['decrypted_string']) {
                            setMessageResponse(data['decrypted_string']);
                            if (data['presignedFileURL']) {
                                setFileResponse(data.presignedFileURL)
                                setFileDeleteURL(data.presignedDeleteURL)
                                setFileDetails({
                                    name: data.file_name,
                                    size: data.file_size
                                })
                                // console.log("data",data)
                            }
                        } else if (data['record_no_exist']) {
                            setRecordNoExist(true)
                        } else {
                            console.log('ERROR') //ToDo: Add error handling
                        }
                        setResponseReceived(true)
                    }
                )
        }
    }, [setMessageResponse, setResponseReceived, props.messageToEncrypt, props.ttl, props.delete_on_view, props.guid, props.action, props.fileHolder])

    //Passes message and file back up to modal so it can be copied
    props.receiveValue(messageResponse)

    //Handle text for put or retrieve
    if (props.action === "put") {
        dialogContentTop = 'Send this URL to the intended recipient. With default settings, it can only be opened once before it is destroyed!'
        dialogContentMessage = messageResponse
    } else if (props.action === "retrieve") {
        //If there is a messageResponse give it to the user, else there is no response, serve the user the no response message
        if (messageResponse) {
            if (props.delete_on_view) {
                dialogContentTop = 'Someone has sent you a message. Remember to save it before you close this window or your message will be gone forever.'
            } else {
                dialogContentTop = 'Someone has sent you a message. It will expire soon so remember to save it before you close this window.'
            }
            dialogContentMessage = messageResponse
        } else if (recordNoExist) {
            dialogContentTop = 'Nothing to see here. This message has either already been deleted (someone opened it or it timed out) or the message never existed. Ask the sender to regenerate your message.'
        }

    } else {
        console.log('ERROR') //ToDo: handle error
    }

    function showFileDetails(file) {
        if (file) {
            return (
                <FileDetails file={file}/>
            )
        }

    }

    function UpdateFileDownloadedStatus(DownloadedStatus) {
        setFileDownloaded(DownloadedStatus)
    }
    function fileDownloadButton(fileLink, fileDeleteURL, fileName) {
        if ( !(fileLink.length === null) && !(fileLink.length === 0) && !(fileLink === false) && (fileDownloaded === false)) {
            return (
                <div onClick={()=>downloadFile(fileLink, fileDeleteURL, UpdateFileDownloadedStatus, fileName )}>
                        <Grid container spacing={2}>
                            <Grid item md={6} >
                                {showFileDetails(fileDetails)} 
                            </Grid>
                        </Grid>
                </div>
            )
        }
    }

    function fileUploadHandler(filePutResponse) {

        if (props.fileHolder) {
            if (filePutResponse === false){
                return (
                    <Typography align="center" className={classes.fileUploadMessage}>
                        {/* Uploading file... please be patient. */}
                    </Typography>
                )
            } else {
                if (!(filePutResponse === "success")) {
                    function errorText() {
                        if (typeof filePutResponse === "string"){
                            if (filePutResponse.includes("EntityTooLarge")){
                    
                                const maxFileSize = humanReadableBytes(filePutResponse.split('<MaxSizeAllowed>')[1].split('</MaxSizeAllowed>')[0])
                                return(
                                    <div>
                                        <Typography> <strong> File too large </strong> </Typography>
                                        <Typography> Max size: {maxFileSize} </Typography> 
                                    </div>
                                    
                                )
                            } else {
                                return(
                                    <div className={classes.codeBlock}>
                                        {filePutResponse}
                                    </div>
                                )
                            }
                        }
                    }
                    return (
                        <Container maxWidth="xs" disableGutters={true} >
                            <Alert severity="error">
                                <AlertTitle><strong>Error: File Not Uploaded</strong></AlertTitle>
                                <Typography> Your message is still accessible from the URL. </Typography> 
                                {errorText()}
                            </Alert>
                        </Container>
                    )
                }
                
            }
        }
    }

    function dialogContentMessageBox(message) {
        if (!(message.length === null) && !(message.length === 0)) {
            return (
                <Paper className={classes.returnContent} elevation={4} id='modalContentReturnPaper'>
                    {dialogContentMessage.toString()}
                </Paper>
            )
        }
    }

    //This is needed so that react does not constantly re-render the 
    useEffect(() => {
        makeApiCall()
    }, [makeApiCall])

    //Return loading wheel while waiting on api call, then replace loading wheel with content
    if (responseReceived === true) {
        props.showButtonsHandler(true)
        return (
            <Container disableGutters={true}>
                <DialogContentText id="alert-dialog-description">
                    {dialogContentTop}
                </DialogContentText>
                <DialogContentText>
                    {fileUploadHandler(filePutResponse)}
                    {dialogContentMessageBox(dialogContentMessage)}
                    {fileDownloadButton(fileResponse, fileDeleteURL, fileDetails.name)}
                </DialogContentText>
            </Container>
        );
    }
    else if (responseReceived === "Errorfalse") {
        return (
            <Alert severity="error" >
                <AlertTitle>Error</AlertTitle>
                <strong>An error has occurred, please try again.</strong>
            </Alert>
        );
    }
    else {
        props.showButtonsHandler(false)
        return (
            <Container className={classes.loadingIconContainer} disableGutters={true}>
                <CircularProgress size='5em' className={classes.loadingIcon} />
                {fileUploadHandler(filePutResponse)}
            </Container>
        );
    }
}

export default PutReturnContent;