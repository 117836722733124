import sanitizeS3FileName from 'functions/sanitize-s3-file-name.js';

async function putSecretString(url, string, ttl, delete_on_view, file) {
    
    let body
    if (file) {
        body = {
            to_encrypt: string,
            ttl: ttl,
            delete_on_view: delete_on_view,
            file_bool: true,
            file_name: sanitizeS3FileName(file.name),
            file_size: file.size
        }
    } else {
        body = {
            to_encrypt: string,
            ttl: ttl,
            delete_on_view: delete_on_view,
        }
    }

    //Prepare options for fetch
    const requestConfig = {
        method: 'POST',
        body: JSON.stringify(body),
    };
        
    //https://hashnode.com/post/javascript-fetch-retry-upon-failure-cjf0654cj001gmps1o754n9xc
    const fetch_retry = async (url, requestConfig, n) => {
        try {
            return await fetch(url, requestConfig)
                .then(response => response.json())
                .then(data => {
                    if (data['message']) {
                        const message = `An error has occurred: ${data.status}`;
                        throw new Error(message); //ToDo add error handling such as a retry or let the user know something went wrong
                    }
                    return data
                })
        } catch(error) {
            if (n === 1) return {"error": error};
            return await fetch_retry(url, requestConfig, n - 1);
        }
    };

    return fetch_retry(url, requestConfig, 3)
        
}

export default putSecretString;