import React from 'react';
import { Typography, Grid, Container } from '@material-ui/core';
import {useLocation} from "react-router-dom";
import { Alert, AlertTitle } from '@material-ui/lab';

// ------- Components -------
import SignetModal from 'components/modal';

// ------- Styles -------
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
    root:{
        padding: theme.spacing(20,0,6),
        flexGrow: 1,
    },

    headers: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(0,0,4)
    },


    landingSection: {
        height: '100vh'
    },

    warningHeader: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    
}));

// ----- End Styles -----


function Retrieve() {
    const classes = useStyles();
    
    const search = useLocation().search;
    const GUID = new URLSearchParams(search).get('guid');
    const multi = new URLSearchParams(search).get('multi'); 
    const delete_on_view = !multi;
    
    function setWarningText(multi) {
        if (multi) {
            return (
            <Alert severity="warning" className={classes.warningHeader} >
                <AlertTitle>Warning</AlertTitle>
                <strong>This message is scheduled to expire. Make sure to save this somewhere secure like a password manager.</strong>
            </Alert>
            )
        }
        else {
            return (
            // <Typography className={classes.warningHeader} variant="h6" align ="center" color="textSecondary" paragraph>
            //     WARNING! Once a message has been opened it is gone forever! Make sure to save this somewhere secure like a password manager.
            // </Typography>
            <Alert severity="error" className={classes.warningHeader} >
                <AlertTitle>Warning</AlertTitle>
                <strong>Once this message has been opened it will be gone forever! Make sure to save this somewhere secure like a password manager.</strong>
            </Alert>
            )
        }
    }

    return (
        <div className={classes.root}>
            <section className={classes.landingSection}>
                <Container maxWidth="md">
                    <div className={classes.headers}>
                        <Typography variant ='h2' align ="center" color="textPrimary"  gutterBottom>
                            Signet Seal
                        </Typography>
                        <Typography variant="h4" align ="center" color="textSecondary" paragraph>
                            Your message is here, would you like to open it now?
                        </Typography>
                        {setWarningText(multi)}
                    </div>
                    <div className={classes.buttons}> 
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item>
                                <SignetModal title="Your message has been retrieved" action="retrieve" guid={GUID} delete_on_view={delete_on_view} primaryButtonMessage ="Retrieve Your Encrypted Message" />
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </section>
        </div>
    );
};

export default Retrieve;