import React from "react";
import { Container, Typography, Grid } from '@material-ui/core';

// ----------- Icons -------------
import VpnLockTwoToneIcon from '@material-ui/icons/VpnLockTwoTone';
import LockIcon from '@material-ui/icons/Lock';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import CloudIcon from '@material-ui/icons/Cloud';
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone';
import CloudTwoToneIcon from '@material-ui/icons/CloudTwoTone';

// ----------- Images -------------
import slackLogo from 'assets/slack-logo-svg-vector.svg';

// ------- Styles -------
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      textAlign: 'center',
      padding: theme.spacing(20,0,6),
    },

    typography: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },

    svgIcons: {
        fontSize: "6em",
        color: theme.palette.secondary.main,
    },

    svgImage: {
      height: 100
    },
     
    header: {
      paddingTop: 50,
    },
  }));
// ----- End Styles -----

export default function Features() {

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant ='h2' align ="center" color="textPrimary"  gutterBottom>
        Features
      </Typography>
      <Container maxWidth="sm">
        <Typography variant ='h6'>
          Signet Seal is designed with a security first approach for your data. We continuously strive to provide the highest level of security, privacy, value, and user experience. 
        </Typography>
      </Container>
      
      <Container maxWidth="lg" className={classes.header}>
        <Grid container spacing={3}>

          <Grid item xs>
            <VisibilityTwoToneIcon className={classes.svgIcons}></VisibilityTwoToneIcon>
            <Typography variant ='h4' align ="center" color="textPrimary"  gutterBottom> One Time Retrieval </Typography>
            <Typography className={classes.typography}>Once a message has been retrieved we delete it from our database. No logs, records, or backups with the message are retained. It is gone for good.</Typography>
          </Grid>

          <Grid item xs>
            <VpnLockTwoToneIcon className={classes.svgIcons}></VpnLockTwoToneIcon>
            <Typography variant ='h4' align ="center" color="textPrimary"  gutterBottom> Secure Storage </Typography>
            <Typography className={classes.typography}>Signet Seal utilizes cutting edge encryption technology to ensure your message is secure. Your message is doubly encrypted both at rest and in transit.</Typography>
          </Grid>

          <Grid item xs>
            <LockIcon className={classes.svgIcons}></LockIcon>
            <Typography variant ='h4' align ="center" color="textPrimary"  gutterBottom> In-browser Encryption </Typography>
            <Typography className={classes.typography}>Your message is encrypted in the browser using industry standard AES-256 bit encryption and can only be decrypted using the special link generated by the browser.</Typography>
          </Grid>

        </Grid>
        <Grid container spacing={3}>

          <Grid item xs>
            <AccessAlarmIcon className={classes.svgIcons} ></AccessAlarmIcon>
            <Typography variant ='h4' align ="center" color="textPrimary"  gutterBottom> Timed Deletion (TTL) </Typography>
            <Typography className={classes.typography}>If your message isn't retrieved within the amount of time you define it is deleted for your security, even if no one ever viewed it.</Typography>
          </Grid>

          <Grid item xs>
            <CloudTwoToneIcon className={classes.svgIcons}></CloudTwoToneIcon>
            <Typography variant ='h4' align ="center" color="textPrimary"  gutterBottom> Serverless </Typography>
            <Typography className={classes.typography}>Signet Seal runs on a serverless architecture which provides a high level of security and performance. By utilizing resources only when needed, Signet Seal provides an unparalleled price-performance ratio.</Typography>
          </Grid>

          <Grid item xs>
            <CloudIcon className={classes.svgIcons}></CloudIcon>
            <Typography variant ='h4' align ="center" color="textPrimary"  gutterBottom> AWS </Typography>
            <Typography className={classes.typography}>Signet Seal is powered by Amazon Web Services (AWS). AWS enables Signet Seal with the cutting edge serverless technology to continuously provide outstanding service on a global scale.   </Typography>
          </Grid>

        </Grid>
        <Grid container spacing={3}>

          <Grid item xs>
            <Container maxWidth="sm">
              <img src={slackLogo} alt="Slack logo" className={classes.svgImage} />
              <Typography variant ='h4' align ="center" color="textPrimary"  gutterBottom> Slack Integration </Typography>
              <a href="https://slack.com/oauth/v2/authorize?client_id=2162006238596.2179667679616&scope=channels:history,commands,incoming-webhook&user_scope=">
                <img alt="Add to Slack" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" />
              </a>
              <Typography className={classes.typography}>Install the Signet Seal Slack application on your Slack workspace. Conveniently encrypt your messages with a /seal slash command.</Typography>
            </Container>
          </Grid>

        </Grid>
      </Container>
    </div>
  );
}
