import React from 'react';
import { Route, Switch } from "react-router-dom";
import {ThemeProvider, CssBaseline } from '@material-ui/core';
import useThemes from 'themes';

// ------- Pages -------
import Home from 'pages/home.jsx';
import Retrieve from 'pages/retrieve.jsx';
import features from 'pages/features.jsx';
import howItWorks from 'pages/howitworks.jsx';
import ContactUs from 'pages/contactus.jsx';
import requestDemo from 'pages/requestdemo.jsx';
import error404 from 'pages/404.jsx';

// ------- Components -------
import ScrollToTop from 'components/scroll-to-top';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';

// ------- Styles -------
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    contentToRender: {
        backgroundColor: theme.palette.background.paper,
    },

    forceFullHeight: {
        height:"100vh",
        position: 'absolute',
        zIndex: '1'
    },
    navBar: {
        padding: "28px"
    },
}));
// ----- End Styles -----

const App = () => {
    const classes = useStyles();

    return (
        <ThemeProvider theme={useThemes}>
            <CssBaseline />
            <main>
                <div className={classes.navBar}>
                    <Navbar/>
                </div>
                    <div className={classes.contentToRender}>
                        <div className={classes.forceFullHeight}> </div>
                        <ScrollToTop />
                        <Switch>
                            <Route path='/' component={Home} exact/>
                            <Route path='/retrieve' component={Retrieve} exact/>
                            <Route path='/features' component={features} />
                            <Route path='/howitworks' component={howItWorks} exact/>
                            <Route path='/contactus' component={ContactUs} exact/>
                            <Route path='/requestdemo' component={requestDemo} exact/>
                            <Route component={error404}/>
                        </Switch>
                    </div>   
                <Footer/> 
            </main>
        </ThemeProvider>
    );
}

export default App;