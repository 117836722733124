import React, {
    useState,
    useEffect
} from "react";
import { Link as RouterLink } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    Grid,
    IconButton,
    Drawer,
    Link,
    MenuItem,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import logo from 'assets/logo_small.png';

// ------- Styles -------
import {
    makeStyles
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    PrettyTitles: {
        textDecoration: "none", 
        color: "inherit",
        '&:hover': {
            backgroundColor: "inherit",
        },
    },
    ToolbarIcon: {
        marginRight: '20px',
        marginTop: '5px'
    },
    signet: {
        display: "inline-flex",
        alignItems: "center"
    },
    header: {
        paddingRight: "5%",
        "@media (max-width: 900px)": {
            paddingLeft: 0,
            paddingRight: "0%",
        },
    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
        color: "inherit",
    },
    drawerContainer: {
        padding: "20px 30px",
    },
    requestDemoButton: {
        color: "red !important", 
        size : "large !important",
        variant: "contained",
    },
}));
// ----------------------

const headersData = [{
        label: "Home",
        href: "/",
    },
    {
        label: "Features",
        href: "/",
        hash: "#features"
    },
    {
        label: "How it Works",
        href: "/howItWorks",
    },
    {
        label: "Contact Us",
        href: "/contactUs",
    },
    {
        label: "Request Demo",
        href: "/requestDemo",
    }
];


function Navbar() {
    const {
        header,
        menuButton,
        requestDemoButton,
        toolbar,
        drawerContainer,
        ToolbarIcon,
        PrettyTitles,
        signet
    } = useStyles();

    const [state, setState] = useState({
        mobileView: false,
        drawerOpen: false,
    });

    const {
        mobileView,
        drawerOpen
    } = state;

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900 ?
                setState((prevState) => ({
                    ...prevState,
                    mobileView: true
                })) :
                setState((prevState) => ({
                    ...prevState,
                    mobileView: false
                }));
        };

        setResponsiveness();

        window.addEventListener("resize", () => setResponsiveness());
    }, []);

    const logoButton = () => {
        return (
            <RouterLink to="/" className={PrettyTitles}  style={{textDecoration: "none"}}>
                <div className={signet}>
                    <img src={logo} alt="Logo" className={ToolbarIcon} />
                    <Typography noWrap variant="h4">
                        Signet Seal
                    </Typography>
                </div>
            </RouterLink>
        )
    }

    const displayDesktop = () => {
        return ( 
        <Toolbar className = {toolbar}>
            {logoButton()}
            <Grid container justifyContent = "flex-end"  >
                <div>
                    {getMenuButtons()} 
                </div> 
            </Grid> 
        </Toolbar>
        );
    };

    const displayMobile = () => {
        const handleDrawerOpen = () =>
            setState((prevState) => ({
                ...prevState,
                drawerOpen: true
            }));
        const handleDrawerClose = () =>
            setState((prevState) => ({
                ...prevState,
                drawerOpen: false
            }));

        return ( 
        <Toolbar >
            {logoButton()}
            <Grid container justifyContent = "flex-end" >
                <IconButton {
                    ...{
                        edge: "end",
                        color: "inherit",
                        "aria-label": "menu",
                        "aria-haspopup": "true",
                        onClick: handleDrawerOpen,
                    }
                }>
                    <MenuIcon/>
                </IconButton>
            </Grid> 
            
            <Drawer
                {
                    ...{
                        anchor: "right",
                        open: drawerOpen,
                        onClose: handleDrawerClose,
                    }
                } 
            >
                <div className = { drawerContainer }> 
                    {getDrawerChoices()} 
                </div> 
            </Drawer>

            </Toolbar>
        );
    };

    const getDrawerChoices = () => {
        return headersData.map(({
            label,
            href,
            hash
        }) => {
            if (typeof hash == "undefined") {
                return (
                    <Link 
                        {
                            ...{
                                component: RouterLink,
                                to: href,
                                color: "inherit",
                                style: {
                                    textDecoration: "none"
                                },
                                key: label,
                            }
                        } 
                    >
                        <MenuItem> {label} </MenuItem> 
                    </Link>
                );
            } else {
                return (
                    <Link 
                        {
                            ...{
                                component: HashLink,
                                to: href + hash,
                                color: "inherit",
                                style: {
                                    textDecoration: "none"
                                },
                                key: label,
                            }
                        } 
                    >
                        <MenuItem> {label} </MenuItem> 
                    </Link>
                )
            }
        });
    };

    const getMenuButtons = () => {
        return headersData.map(({
            label,
            href,
            hash
        }) => {
            if (typeof hash == "undefined") {
                return ( 
                    <Button {
                        ...{
                            key: label,
                            color: "inherit",
                            to: href,
                            component: RouterLink,
                            className: menuButton,
                        }
                    } >
                        { label } 
                    </Button>
                );
            } else if (label === "Request Demo") {
                return (<Button {
                    ...{
                        key: label,
                        color: "inherit",
                        to: href + hash,
                        component: HashLink,
                        className: requestDemoButton,
                    }
                } >
                        { label } 
                    </Button>
                );
            }
            
            else {
                return ( 
                    <Button {
                        ...{
                            key: label,
                            color: "inherit",
                            to: href + hash,
                            component: HashLink,
                            className: menuButton,
                        }
                    } >
                        { label } 
                    </Button>
                );
            }

        });
    };

    return ( 
        <header >
            <AppBar position="fixed" className = { header }>
                {
                    mobileView ? displayMobile() : displayDesktop()
                } 
            </AppBar> 
        </header>
    );
}

export default Navbar;