import { React, useState } from 'react';
import { Typography, Grid, Container, Paper, Switch, TextField, InputLabel, FormControl, Select } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// ------- Components -------
import SignetModal from 'components/modal';
import FileUploader from 'components/file-uploader';
import FileDetails from 'components/file-details';
import ContactForm from 'components/contact-form';
import HowToUseSignetVideos from 'components/how-to-use-signet-videos';

// -----------Functions------------
import obfuscateFileName from 'functions/obfuscate-string.js';

// -----------Icons -------------
import LockIcon from '@material-ui/icons/Lock';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import VpnLockTwoToneIcon from '@material-ui/icons/VpnLockTwoTone';
import CloudIcon from '@material-ui/icons/Cloud';
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone';
import CloudTwoToneIcon from '@material-ui/icons/CloudTwoTone';

// ----------- Images -------------
import slackLogo from 'assets/slack-logo-svg-vector.svg';
import safe2 from 'assets/safe2.png';
import cloud_security_lock_icon from 'assets/cloud_security_lock_icon.svg';

// ------- Styles -------
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    headers: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(0, 0, 4)
    },

    buttons: {
        marginTop: '2em',
    },

    settings: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(4, 0, 2)
    },

    landingSection: {
        [theme.breakpoints.down('sm')]: {
            height: '160vh',
        },

        [theme.breakpoints.up('sm')]: {
            height: '100vh',
        },
        margin: "28px",
        marginBottom: "60"
    },

    image: {
        width: 128,
        height: 128,
    },

    image2: {
        width: 256,
        height: 256,
        padding: "5px",
    },

    slackButton: {
        padding: '28px'
    },

    slack: {
        padding: '28px'
    },

    typography: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,

    },

    typographyFeatures: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        minWidth: '220px',
    },

    svgIcons: {
        fontSize: "6em",
        color: theme.palette.secondary.main,
        align: 'center',
        justify: 'center',
    },

    containerWhatWhy: {
        paddingTop: 50,
        marginBottom: '2em',
        borderColor: 'red'
    },

    whatWhyCard: {
        padding: '38px',
        minWidth: '220px',
        minHeight: '300px'
    },

    scrollButtonContainer: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '1em'
        },

        [theme.breakpoints.up('sm')]: {
            marginTop: '2em'
        },
    },

    scrollButton: {
        align: 'center',
        justify: 'center',
        justifyContent: 'center',
        fontSize: "4rem",
        marginTop: '-15px'
    },

    sectionWhatWhy: {
        paddingBottom: "28px",
        paddingTop: "28px",
        backgroundColor: theme.palette.primary.main,
    },

    cardGrid: {
        marginBottom: '2em',
        maxWidth: 1024,
    },

    svgImage: {
        paddingTop: '5px',
        height: 100
    },

    root: {
        flexGrow: 1,
        textAlign: 'center',
        padding: "28px",
    },

    features: {
        flexGrow: 1,
        textAlign: 'center',
        padding: "28px",
        paddingTop: "65px"
    },

    header: {
        paddingTop: 50,
    },

    entryText: {
        marginLeft: '-10px',
        marginRight: '-20px',
        marginTop: '-18px',
        textAlign: 'center'
    },
}));

// ----- End Styles -----


function Home() {
    const charLimit = 40000;
    const maxFileSize = 4194304 //4 MB max file size
    const [messageToEncrypt, setMessageToEncrypt] = useState('');
    const [TTL, setTTL] = useState('432000');
    const classes = useStyles();
    const [fileHolder, setFileHolder] = useState(false);
    const [deleteOnView, setDeleteOnView] = useState({
        deleteOnViewSwitch: true,
    });
    const [originalFile, setOriginalFile] = useState(false);
    

    const handleChange = (deleteOnView) => {
        setDeleteOnView({ ...deleteOnView, [deleteOnView.target.name]: deleteOnView.target.checked })
    };

    function scrollToID(id) {
        const view = document.getElementById(id);
        view.scrollIntoView({ alignToTop: true, behavior: 'smooth' });
    }

    function clearMessageTextField() {
        setMessageToEncrypt('')
    }

    function handleFile(file) {
        setOriginalFile(file)
        setFileHolder(file)
    }

    function obfuscateFile(checked, file) {
        if (checked)
            setFileHolder(obfuscateFileName(file))
        else
            setFileHolder(originalFile)
    }

    function showFileDetails(file) {
        if (file) {
            return (
                <FileDetails file={file} obfuscationFunc={obfuscateFile} usageType="upload" />
            )
        }
    }

   
    return (
        <div>
            <div className={classes.root}>
                <section className={classes.landingSection}>
                    <Container maxWidth="sm">
                        <div className={classes.headers}>
                            <Typography variant='h2' align="center" color="textPrimary" gutterBottom>
                                Signet Seal
                            </Typography>
                            <Typography variant="h5" align="center" color="textSecondary" paragraph>
                                Encrypt a password, secret, message, or file for secure transmission
                            </Typography>
                        </div>
                        <div className={classes.entryText}>
                            <TextField
                                id="messageBox"
                                label="Enter your message to encrypt"
                                // autoFocus={true} #Removed due to glitching videos on home page
                                multiline={true}
                                inputProps={{ maxLength: charLimit }}
                                variant="outlined"
                                fullWidth={true}
                                maxRows="10"
                                onChange={message => setMessageToEncrypt(message.target.value)}
                                helperText={`${messageToEncrypt.length}/${charLimit}`}
                                value={messageToEncrypt}
                            />
                        </div>
                        <div className={classes.buttons}>
                            <Grid container spacing={2} justify="center">
                                <Grid item>
                                    <SignetModal messageToEncrypt={messageToEncrypt} ttl={TTL} fileHolder={fileHolder} delete_on_view={deleteOnView.deleteOnViewSwitch} title="Your message has been securely stored" action="put" primaryButtonMessage="Seal Your Message" clearMessageTextField={clearMessageTextField} />
                                </Grid>
                                <Grid item >
                                    <FileUploader handleFile={handleFile} maxFileSize={maxFileSize} />
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            <Grid container spacing={2} justify="center">
                                <Grid item>
                                    {showFileDetails(fileHolder)}
                                </Grid>
                            </Grid>
                        </div>

                        <div className={classes.settings}>
                            <Grid container spacing={2} justify="center">
                                <Grid item>
                                    <FormControl variant="outlined">
                                        <InputLabel htmlFor="settings-lifespan">Lifespan</InputLabel>
                                        <Select
                                            onChange={TTL => setTTL(TTL.target.value)}
                                            native
                                            label="Lifespan"
                                            id="stringToEncryptField"
                                            defaultValue={"432000"}
                                            inputProps={{
                                                name: 'Lifespan',
                                                id: 'settings-lifespan',
                                            }}
                                        >
                                            <option value={"1800"}>30 Minutes</option>
                                            <option value={"3600"}>1 Hour</option>
                                            <option value={"10800"}>3 Hours</option>
                                            <option value={"18000"}>5 Hours</option>
                                            <option value={"28800"}>8 Hours</option>
                                            <option value={"43200"}>12 Hours</option>
                                            <option value={"86400"}>1 Day</option>
                                            <option value={"259200"}>3 Days</option>
                                            <option value={"432000"}>5 Days</option>
                                            <option value={"604800"}>7 Days</option>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {/* Hidden until we actually have more settings and need the help button */}
                                {/* <Grid item>
                                    <Tooltip title="Click to see a detailed description of all settings" >
                                        <IconButton color="primary"> 
                                            <Help fontSize="large" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid> */}
                            </Grid>
                        </div>
                        <Grid container spacing={2} justify="center">
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={deleteOnView.deleteOnViewSwitch}
                                            onChange={handleChange}
                                            color="primary"
                                            name="deleteOnViewSwitch"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    }
                                    label="Delete on view"
                                />
                            </Grid>
                        </Grid>
                    </Container>

                    <Container align="center" onClick={() => scrollToID('whatWhy')} maxWidth={false} className={classes.scrollButtonContainer}>
                        <Typography color="primary">Learn More</Typography>
                        <ExpandMoreRoundedIcon color="primary" className={classes.scrollButton} />
                    </Container>
                </section>
            </div>

            <section id="whatWhy" className={classes.sectionWhatWhy}>
                <Container maxWidth="md" className={classes.containerWhatWhy} justify="center" align="center">
                    <Grid container spacing={3}>
                        <Grid item xs >
                            <Paper elevation={3} className={classes.whatWhyCard}>
                                <img src={cloud_security_lock_icon} alt="cloudLock" className={classes.image2} />
                                <Typography variant='h4' align="center" color="textPrimary" gutterBottom> What is Signet Seal? </Typography>
                                <Typography className={classes.typography}>Signet Seal allows you to encrypt a message and generates a link for viewing your message. Your message may only be viewed once, then it is deleted. Your message expires after a pre-configured time (default 5 days) of not being viewed. This ensures your secret message won't be left in someones message history. </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs>
                            <Paper elevation={3} className={classes.whatWhyCard}>
                                <img src={safe2} alt="Safe" className={classes.image2} />
                                <Typography variant='h4' align="center" color="textPrimary" gutterBottom> Why use Signet Seal? </Typography>
                                <Typography className={classes.typography}>Have you ever needed to send someone a password, credit card information, or other data which needed to be kept a secret? If you emailed, messaged, or texted that information then it is there forever. Signet Seal lets you send that information knowing it won't be left lying around for someone to find later.</Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </section>

            <section id="videos" className={classes.sectionWhatWhy}>
                <HowToUseSignetVideos/>
            </section>

            <section id="features">
                <Container justify="center" align="center" >
                    <div className={classes.features}>
                        <Typography variant='h2' align="center" color="textPrimary" gutterBottom>
                            Features
                        </Typography>
                        <Container maxWidth="sm">
                            <Typography variant='h6'>
                                Signet Seal is designed with a security first approach for your data. We continuously strive to provide the highest level of security, privacy, value, and user experience.
                            </Typography>
                        </Container>

                        <Container maxWidth="lg" className={classes.header}>
                            <Grid container spacing={3}>
                                <Grid item xs>
                                    <VisibilityTwoToneIcon className={classes.svgIcons}></VisibilityTwoToneIcon>
                                    <Typography variant='h4' align="center" color="textPrimary" gutterBottom> One Time Retrieval </Typography>
                                    <Typography className={classes.typographyFeatures}>Once a message has been retrieved we delete it from our database. No logs, records, or backups with the message are retained. It is gone for good.</Typography>
                                </Grid>
                                <Grid item xs>
                                    <VpnLockTwoToneIcon className={classes.svgIcons}></VpnLockTwoToneIcon>
                                    <Typography variant='h4' align="center" color="textPrimary" gutterBottom> Multi-View Messages </Typography>
                                    <Typography className={classes.typographyFeatures}>Perfect for group chats or teams, Signet Seal allows you to encrypt a message to be opened any number of times until the TTL (time to live) expires. Just uncheck the "Delete on view" switch.</Typography>
                                </Grid>
                                <Grid item xs>
                                    <LockIcon className={classes.svgIcons}></LockIcon>
                                    <Typography variant='h4' align="center" color="textPrimary" gutterBottom> In-browser Encryption </Typography>
                                    <Typography className={classes.typographyFeatures}>Your message is encrypted in the browser using industry standard AES-256 bit encryption and can only be decrypted using the special link generated by the browser.</Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs>
                                    <AccessAlarmIcon className={classes.svgIcons} ></AccessAlarmIcon>
                                    <Typography variant='h4' align="center" color="textPrimary" gutterBottom> Timed Deletion (TTL) </Typography>
                                    <Typography className={classes.typographyFeatures}>If your message isn't retrieved within the amount of time you define it is deleted for your security, even if no one ever viewed it.</Typography>
                                </Grid>
                                <Grid item xs>
                                    <CloudTwoToneIcon className={classes.svgIcons}></CloudTwoToneIcon>
                                    <Typography variant='h4' align="center" color="textPrimary" gutterBottom> Secure File Sharing </Typography>
                                    <Typography className={classes.typographyFeatures}>Upload files up to 4MB and get a one time use link or flip the "Delete on view" switch to allow the link to be used until it expires. </Typography>
                                </Grid>
                                <Grid item xs>
                                    <CloudIcon className={classes.svgIcons}></CloudIcon>
                                    <Typography variant='h4' align="center" color="textPrimary" gutterBottom> AWS Serverless</Typography>
                                    <Typography className={classes.typographyFeatures}>Signet Seal is powered by Amazon Web Services (AWS). AWS enables Signet Seal with the cutting edge serverless technology to continuously provide outstanding service on a global scale.   </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs>
                                    <Container className={classes.slack} maxWidth="sm">
                                        <img src={slackLogo} alt="Slack logo" className={classes.svgImage} />
                                        <Typography variant='h4' align="center" color="textPrimary" gutterBottom> Slack Integration </Typography>
                                        <a href="https://slack.com/oauth/v2/authorize?client_id=2162006238596.2179667679616&scope=channels:history,commands,incoming-webhook&user_scope=">
                                            <img alt="Add to Slack" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" />
                                        </a>
                                        <Typography className={classes.typography}>Install the Signet Seal Slack application on your Slack workspace. Conveniently encrypt your messages with a /signet slash command.</Typography>
                                    </Container>
                                </Grid>
                            </Grid>
                        </Container>


                        {/* insert here */}
                        <ContactForm />
                    </div>
                </Container>
            </section>
        </div>
    );
};

export default Home;
