import axios from 'axios';

//https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/modules/_aws_sdk_s3_presigned_post.html
async function putSecretFile(responseAPI, file) {

  async function myFetch() {
    let formData = new FormData();
    Object.entries(responseAPI.fields).forEach(([field, value]) => {
      formData.append(field, value);
    });
    formData.append('Content-Type', file.type);
    formData.append("file", file);
    
    // post the data on the s3 url
    return await axios.post(responseAPI.url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
        }              
    }).then(
      function (response) {
        return "success"
      })
      .catch(function (error) {
        try {
          let errorToReturn=(error.response.data.split(/\n/)[1]);
          return errorToReturn
        } catch {
          return error
        }
    }); 
  }
  return await myFetch()
}

export default putSecretFile;