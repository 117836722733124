import React from "react";
import { Container, Typography, Grid } from '@material-ui/core';

// ------- Icons -------
import BackupIcon from '@material-ui/icons/Backup';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

// ------- Components -------
import HowToUseSignetVideos from 'components/how-to-use-signet-videos';

// ------- Images -------
import putDiagram from 'assets/put_diagram_for_users.svg';
import retrieveDiagram from 'assets/retrieve_diagram_for_users.svg';

// ------- Styles -------
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: "28px"
  },

  diagramImage: {
    width: "100%"
  },

  Section: {
    padding: theme.spacing(4,0,0)
  },

  Paragraphs: {
    color: theme.palette.text.secondary,
  },

  Typography: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minWidth: '220px', 
    
  },

  svgIcons: {
      fontSize: "6em",
      color: theme.palette.secondary.main,
    },

  cards: {
    flexGrow: 1,
    textAlign: 'center',
  },

  videoContainer: {
    marginTop: "20px",
  },

}));
// ----------------------


function HowItWorks() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Typography variant ='h2' align ="center" color="textPrimary" gutterBottom>
          How It Works
        </Typography>
        <Typography color="textPrimary" className={classes.Paragraphs} gutterBottom>
          Signet Seal is designed and maintained by experienced engineers who saw the problem of securely sending secrets and wanted to solve it in the most secure manner possible using the most cutting edge serverless tooling. 
        </Typography>
        <Typography color="textPrimary" className={classes.Paragraphs} gutterBottom>
        Our infrastructure is 100% AWS serverless with a security over all mindset, architected by AWS certified professionals.
        </Typography>

        <Container className={classes.videoContainer}>
          <HowToUseSignetVideos/>
        </Container>

        <Typography variant ='h3' align ="center" color="textPrimary" className={classes.Section} gutterBottom>
          How You Upload a Secret
        </Typography>
        <img src={putDiagram} alt="How user puts secret diagram" className={classes.diagramImage} />
        <Typography variant ='h3' align ="center" color="textPrimary" className={classes.Section} gutterBottom>
          How You Retrieve a Secret
        </Typography>
        <img src={retrieveDiagram} alt="How user gets secret diagram" className={classes.diagramImage}/>
        <div className={classes.cards}>
          <Grid container spacing={3} className={classes.Section}>
            <Grid item xs>
            <BackupIcon className={classes.svgIcons}></BackupIcon>
            <Typography variant ='h4' align ="center" color="textPrimary"  gutterBottom> Database Security </Typography>
              <Typography className={classes.Typography}>We have chosen not to maintain database backups so as not to  retain messages which should have been deleted. Instead we rely on cross-regional replicas.</Typography>
            </Grid>
            <Grid item xs>
            <DeleteForeverIcon className={classes.svgIcons}></DeleteForeverIcon>
            <Typography variant ='h4' align ="center" color="textPrimary"  gutterBottom> Secure Deletion </Typography>
              <Typography className={classes.Typography}>If configured to do so, your message is deleted upon retrieval before the response even leaves our infrasturcture. Otherwise, it is always deleted after a set amount of time. </Typography>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
    );

}


export default HowItWorks;