
async function putContactForm(url, FirstName, LastName, Email, Phone, ContactMessage) {

    let body = {
        first_name: FirstName,
        last_name: LastName,
        email: Email,
        phone: Phone,
        message: ContactMessage
    };
    

    //Prepare options for fetch
    const requestConfig = {
        method: 'POST',
        body: JSON.stringify(body),
    };
        
    //https://hashnode.com/post/javascript-fetch-retry-upon-failure-cjf0654cj001gmps1o754n9xc
    const fetch_retry = async (url, requestConfig, n) => {
        try {
            return await fetch(url, requestConfig)
                .then(response => response.json())
                .then(data => {
                    return data
                })
        } catch(error) {
            if (n === 1) return {"error": error};
            return await fetch_retry(url, requestConfig, n - 1);
        }
    };

    return fetch_retry(url, requestConfig, 3)
        
}

export default putContactForm;